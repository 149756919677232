import React, {useEffect, useState} from 'react'
import urlSlug from 'url-slug'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    TextField,
    Typography,
    Box,
    Divider,
    Alert,
    IconButton,
    Tooltip
} from "@mui/material";
import {
    Home as HomeIcon,
    Link as LinkIcon,
    CallSplit as RedirectIcon
} from '@mui/icons-material';
import {ObjectID} from "bson";
import {useDispatch} from "react-redux";
import {editUrl, changeArticleField} from "../features/article/articleSlice";
import TownSelector from "./ArticleTownDetails/TownSelector";

export default function EditUrl({url, onClose}) {
    const dispatch = useDispatch();
    const [editingUrl, setEditingUrl] = useState(url || {
        id: new ObjectID().toString(),
        is_primary: true,
        type: "article",
        url: '',
        town: {}
    });

    // Separate state for redirect fields for better UX
    const [redirectInfo, setRedirectInfo] = useState({
        isRedirect: !!url?.redirect_to,
        type: url?.redirect_type || 301, // Default to permanent redirect
        to: url?.redirect_to || ''
    });

    // Set up initial town from localStorage if needed
    useEffect(() => {
        if (!editingUrl.town?.name) {
            try {
                const storedTown = localStorage.getItem('town');
                const storedRegion = localStorage.getItem('region');
                if (storedTown && storedRegion) {
                    const town = JSON.parse(storedTown);
                    const region = JSON.parse(storedRegion);
                    const defaultTown = {
                        ...town,
                        region: region
                    };
                    setEditingUrl(prev => ({
                        ...prev,
                        town: defaultTown
                    }));
                    dispatch(changeArticleField({
                        town: defaultTown,
                        dirty:false
                    }));
                }
            } catch (e) {
                console.error('Error parsing town from localStorage:', e);
            }
        }
    }, []);

    const handleSave = () => {
        if (editingUrl) {
            const urlToSave = {
                ...editingUrl,
                // Only include redirect fields if it's a redirect
                ...(redirectInfo.isRedirect ? {
                    redirect_to: redirectInfo.to,
                    redirect_type: redirectInfo.type
                } : {})
            };
            dispatch(editUrl(urlToSave));
        }
        onClose();
    };

    const handleTownSelect = (newTown) => {
        setEditingUrl(prev => ({
            ...prev,
            town: newTown
        }));
    };

    const handleUrlChange = (event) => {
        setEditingUrl(prev => ({
            ...prev,
            url: event.target.value
        }));
    };

    // Determine if save should be enabled
    const canSave = editingUrl.town?.name && editingUrl.url && 
        (!redirectInfo.isRedirect || (redirectInfo.to && redirectInfo.type));

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {url ? 'Edit URL' : 'Create URL'}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3} sx={{ mt: 2 }}>
                    <Box>
                        <Typography variant="subtitle2" gutterBottom>URL Details</Typography>
                        <Stack spacing={2}>
                            <TownSelector value={editingUrl.town || {}} onSelect={handleTownSelect} />
                            <TextField
                                label="URL"
                                value={editingUrl.url || ''}
                                onChange={handleUrlChange}
                                fullWidth
                                helperText="The URL path for this article"
                            />
                        </Stack>
                    </Box>

                    <Divider />

                    <Box>
                        <Stack spacing={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={redirectInfo.isRedirect}
                                        onChange={(e) => setRedirectInfo(prev => ({
                                            ...prev,
                                            isRedirect: e.target.checked
                                        }))}
                                    />
                                }
                                label={
                                    <Typography>
                                        Make this URL redirect to another page
                                    </Typography>
                                }
                            />

                            {redirectInfo.isRedirect && (
                                <>
                                    <Alert severity="info" sx={{ mb: 2 }}>
                                        This URL will redirect visitors to another page. 
                                        Choose permanent (301) if this is a long-term change, 
                                        or temporary (302) for a short-term redirect.
                                    </Alert>
                                    <Stack spacing={2}>
                                        <FormControl>
                                            <InputLabel>Redirect Type</InputLabel>
                                            <Select
                                                value={redirectInfo.type}
                                                label="Redirect Type"
                                                onChange={(e) => setRedirectInfo(prev => ({
                                                    ...prev,
                                                    type: e.target.value
                                                }))}
                                            >
                                                <MenuItem value={301}>Permanent (301) - Long-term change</MenuItem>
                                                <MenuItem value={302}>Temporary (302) - Short-term redirect</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Stack direction="row" spacing={1} alignItems="flex-start">
                                            <TextField
                                                label="Redirect To"
                                                value={redirectInfo.to}
                                                onChange={(e) => setRedirectInfo(prev => ({
                                                    ...prev,
                                                    to: e.target.value
                                                }))}
                                                fullWidth
                                                helperText="Enter the URL where visitors should be redirected"
                                                InputProps={{
                                                    startAdornment: (
                                                        <RedirectIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    ),
                                                }}
                                            />
                                            {editingUrl.town?.domain && (
                                                <Tooltip title="Use Town Homepage">
                                                    <IconButton
                                                        onClick={() => setRedirectInfo(prev => ({
                                                            ...prev,
                                                            to: `https://${editingUrl.town.domain}`
                                                        }))}
                                                        sx={{ 
                                                            mt: '3px',
                                                            bgcolor: 'background.paper',
                                                            border: '1px solid',
                                                            borderColor: 'divider',
                                                            '&:hover': {
                                                                bgcolor: 'action.hover',
                                                            }
                                                        }}
                                                        size="large"
                                                    >
                                                        <HomeIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Stack>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button 
                    variant="contained" 
                    onClick={handleSave} 
                    color="primary"
                    disabled={!canSave}
                >
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}