import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useGetArticleQuery, useSaveArticleMutation} from "../features/api/apiSlice";
import {Router, useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import {useDispatch, useSelector} from 'react-redux'
import {
    addPage,
    changeArticleField,
    clear,
    extraFields,
    initialState,
    setAuthor,
    setDirty,
    setStateFromServer
} from "../features/article/articleSlice";
import Pages from "../components/Pages";
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress,
    Portal,
    Snackbar,
    Stack,
    useMediaQuery,
    useTheme,
    Zoom,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Collapse,
    Box
} from "@mui/material";
import {Backspace, Close, NoteAdd, Save, Publish, ArrowDropDown, Schedule, OpenInNew} from "@mui/icons-material";
import ArticleFields from "../components/ArticleFields";
import ArticleTownDetails from "../components/ArticleTownDetails";
import {ObjectID} from "bson";
import {usePrompt} from "../hooks/usePrompt";
import Typography from "@mui/material/Typography";
import ArticleCategory from "../components/ArticleCategory";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import AiToolbox from "../components/Ai/AiToolbox";
import ArticlePublishedOn from "../components/ArticlePublishedOn";
import { RootState } from "../app/store";
import HtmlBlock from "../types/Blocks/HtmlBlock";
import Page from "../types/Page";
import RenderStatusChip from "../renderers/RenderStatusChip";
import { toast } from 'react-toastify';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import Article from "../types/Article";

function ArticleStatusInfo({ status, publishedOn, updatedOn }) {
    const theme = useTheme();
    
    return (
        <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
                <Box>
                    <Typography variant="caption" color="text.secondary" gutterBottom display="block">
                        Status
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <RenderStatusChip value={status} />
                        {status === 'published' && publishedOn && (
                            <Typography variant="body2" color="text.secondary">
                                Published {moment(publishedOn).fromNow()}
                            </Typography>
                        )}
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="caption" color="text.secondary" gutterBottom display="block">
                        Last Updated
                    </Typography>
                    <Typography variant="body2">
                        {moment(updatedOn).format('DD MMM YYYY, HH:mm')}
                    </Typography>
                </Box>
            </Stack>
        </Paper>
    );
}

export default function ArticleSingle() {
    let {articleId, articleType, articleCategory} = useParams();
    let {
        data,
        isLoading,
        isSuccess,
    } = useGetArticleQuery(articleId, {skip: (articleId === 'new')})
    const auth = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [saveArticle, saveArticleData] = useSaveArticleMutation();
    const [saveStatusOpen, setSaveStatusOpen] = React.useState(false);
    const [saveStatusType, setSaveStatusType] = React.useState('success');
    const [publishMenuOpen, setPublishMenuOpen] = useState(false);
    const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
    const [scheduledDate, setScheduledDate] = useState<moment.Moment | null>(null);
    const [flag, setFlag, next] = usePrompt(true);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const article = useSelector((state: RootState) => state.article);
    const isValid = useSelector((state: RootState) => {
        const article = state.article;
        
        // Check for headline
        if (!article.headline?.trim()) {
            return false;
        }

        // Check for featured image
        if (!article.featured_media) {
            return false;
        }

        // Check scheduled date is in the future
        if (article.status === 'scheduled') {
            if (!article.published_on) return false;
            const scheduledDate = moment(article.published_on);
            if (!scheduledDate.isValid() || scheduledDate.isBefore(moment())) {
                return false;
            }
        }

        // Check all blocks are valid
        const allBlocksValid = article.page.every(page => 
            page.blocks.every(block => block.valid !== false)
        );

        return article.dirty && allBlocksValid;
    });

    let [sidebarElem, setSidebarElem] = useState(null);
    const sidebarPortalRef = useCallback((node) => {
        setSidebarElem(node);
    }, [setSidebarElem]);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const currentState = useSelector((state: RootState) => state.article);

    const { data: newData, isSuccess: newSuccess } = useMemo(() => {
        if (articleId !== "new") {
            return { data: null, isSuccess: false };
        }

        function cleanBlockHtml(block, blockIndex, headline) {
            if (block.type !== 'HtmlBlock' || !block.html) {
                return block.html;
            }

            let html = block.html;

            // Clean first block
            if (blockIndex === 0) {
                html = html
                    .replace(new RegExp(`<[^>]*>${headline}</[^>]*>`, 'gi'), '')
                    .replace(new RegExp(headline, 'gi'), '')
                    .replace(/^(<p>|<h[1-6]>)\s*(<\/p>|<\/h[1-6]>)$/g, '')
                    .trim();
            }

            // Clean special characters
            return html
                .replace(/[\u2018\u2019]/g, "'")
                .replace(/[\u201C\u201D]/g, '"')
                .replace(/\u00A0/g, ' ')
                .replace(/\u00C2/g, '')
                .replace(/\u00E2\u20AC\u2122/g, "'");
        }

        // Handle AI generated article case
        if (currentState.isAiGenerated) {
            const cleanedPages = currentState.page.map((page, index) => ({
                ...page,
                update_date: undefined,
                blocks: page.blocks.map((block, blockIndex) => ({
                    ...block,
                    html: cleanBlockHtml(block, blockIndex, currentState.headline)
                }))
            }));

            return {
                data: {
                    ...initialState,
                    id: new ObjectID().toString(),
                    type: articleType,
                    category: articleCategory,
                    headline: currentState.headline,
                    page: cleanedPages,
                    featured_media: currentState.featured_media,
                    tags: currentState.tags,
                    created_on: moment().format("Y-MM-DD\\THH:mm:ssZ"),
                    updated_on: moment().format("Y-MM-DD\\THH:mm:ssZ"),
                    published_on: null,
                    isAiGenerated: true,
                    ...extraFields
                },
                isSuccess: true
            };
        }

        // Create fresh article
        const emptyBlock = {
            id: new ObjectID().toString(),
            type: 'HtmlBlock',
            html: '',
            valid: false,
            classType: 'HtmlBlock'
        } as HtmlBlock;

        const emptyPage = {
            id: new ObjectID().toString(),
            blocks: [emptyBlock],
            page_number: 1,
        } as Page;

        return {
            data: {
                ...initialState,
                id: new ObjectID().toString(),
                type: articleType,
                category: articleCategory,
                page: [emptyPage],
                created_on: moment().format("Y-MM-DD\\THH:mm:ssZ"),
                updated_on: moment().format("Y-MM-DD\\THH:mm:ssZ"),
                published_on: null,
                ...extraFields
            },
            isSuccess: true
        };
    }, [articleId, articleType, articleCategory, currentState]);

    // Set initial state only once when data is available
    useEffect(() => {
        if (articleId === "new") {
            // For new articles, only set state if we don't have an ID yet or if it's the initial state ID
            if (newSuccess && newData && (!article.id || article.id === initialState.id)) {
                dispatch(setStateFromServer(newData));
            }
        } else if (isSuccess && data) {
            // For existing articles, always set state if the IDs don't match
            // This ensures we load the article after publishing/scheduling
            const typedData = data as Article;
            if (article.id !== typedData.id) {
                dispatch(setStateFromServer(data));
            }
        }
    }, [articleId, isSuccess, data, newSuccess, newData, article.id, dispatch]);

    const handleSave = useCallback(() => {
        if (!isValid) return;

        const handleSaveSuccess = (result) => {
            // Only update the state if it's different from current
            if (result.updated_on !== article.updated_on) {
                dispatch(setStateFromServer({
                    ...result,
                    isAiGenerated: false
                }));
            }
            
            const primaryUrl = article.url.find(url => url.is_primary);
            
            toast.success(
                <Stack spacing={1.5} sx={{ 
                    minWidth: 300
                }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body1" fontWeight={500}>
                            Article saved successfully
                        </Typography>
                    </Stack>
                    {primaryUrl && (
                        <Button
                            size="small"
                            startIcon={<OpenInNew fontSize="small" />}
                            onClick={() => window.open(`https://${primaryUrl.town.domain}/${primaryUrl.url}`)}
                        >
                            View on website
                        </Button>
                    )}
                </Stack>
            );
        };

        const handleSaveError = (error) => {
            console.log(error);
            toast.error(
                <Stack spacing={1} sx={{ minWidth: 300 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body1" fontWeight={500}>
                            Something went wrong
                        </Typography>
                    </Stack>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        Please try again or ask Ryan for help
                    </Typography>
                </Stack>
            );
        };

        const purgePrimaryUrl = (result) => {
            const primaryUrl = article.url.find(url => url.is_primary);
            if (!primaryUrl) return;

            const purgeUrl = `https://${primaryUrl.town.domain}/purge?purge=1&url=${encodeURIComponent(`https://${primaryUrl.town.domain}/${primaryUrl.url}`)}`;
            
            fetch(purgeUrl)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.text();
                })
                .then(() => console.log('Page purged successfully'))
                .catch(error => console.error('Purge error:', error));
        };

        const savePromise = saveArticle(article).unwrap();

        if (articleId === "new") {
            savePromise
                .then(result => {
                    setFlag(false);
                    handleSaveSuccess(result);
                    navigate('/articles/' + result.id);
                    setFlag(true);
                })
                .catch(handleSaveError);
        } else {
            savePromise
                .then(result => {
                    handleSaveSuccess(result);
                    purgePrimaryUrl(result);
                })
                .catch(handleSaveError);
        }

    }, [article, articleId, dispatch, isValid, navigate, saveArticle, setFlag]);

    useEffect(() => {
        // Only clear published_on when explicitly changing to draft status
        // and not when loading an existing article
        if(article.status === 'draft' && article.dirty && article.shouldSave) {
            dispatch(changeArticleField({ published_on: null }));
        }
    }, [article.status, article.dirty, article.shouldSave, dispatch]);

    useEffect(() => {
        if (!article.author.id) {
            dispatch(setAuthor({
                id: auth.idTokenClaims['https://admin.eyeon.uk/author_id'],
                name: auth.idTokenClaims['name']
            }))
        }
        // If article has shouldSave flag and is valid, save it
        if (article.shouldSave && isValid) {
            handleSave();
            // Clear the shouldSave flag after saving
            dispatch(changeArticleField({ shouldSave: false }));
        }
    }, [
        article.author.id,
        article.shouldSave,
        auth.idTokenClaims,
        dispatch,
        isValid,
        handleSave
    ]);

    useEffect(() => {
        if (flag) {
            dispatch(clear({}));
            next();
        }
    }, [flag, dispatch, next]);

    const handleAddPage = useCallback(() => {
        dispatch(addPage({}));
    }, [dispatch]);


    const renderEditor = () => {

        let innerContent = (
            <Stack spacing={2} pb={8}>
                <ArticleFields/>
                {(!isDesktop ? (<Box ref={sidebarPortalRef}></Box>) : '')}
                <Tooltip title="Add Update">
                <Box onClick={() => handleAddPage()} sx={{
                    display:'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'height 0.3s ease-in-out',
                    // height: (!isHovering ? defaultHeight : `calc(${defaultHeight} + 20px)`),
                    cursor:'pointer',
                }}>
                        <NoteAdd  />
                </Box>
                </Tooltip>
                <Pages></Pages>
            </Stack>
        )
        let content = innerContent
        if (isDesktop) {
            content = (
                <Grid container direction="row-reverse" spacing={2} sx={{maxWidth: "100%"}}>
                    {(isDesktop ? (
                        <Grid ref={sidebarPortalRef} item xs={12} md={4} sx={{maxWidth: "100%"}}></Grid>) : '')}
                    <Grid item xs={12} md={8} sx={{maxWidth: "100%"}}>
                        {innerContent}
                    </Grid>
                </Grid>
            )
        }
        return content;
    }

    const renderSaveButton = useMemo(() => {
        const isLoading = saveArticleData.isLoading;
        const buttonColor = article.status !== 'published' ? 'secondary' : 'primary';
        
        return (
            <Button
                variant="contained"
                color={buttonColor}
                disabled={isLoading || !isValid}
                onClick={handleSave}
                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <Save />}
            >
                {isLoading ? 'Saving' : 'Save'}
            </Button>
        );
    }, [saveArticleData.isLoading, isValid, handleSave, article.status]);


    const openArticleInNewWindow = () => {
        let articlePrimaryUrl = article.url.find((articleUrl) => {
            return articleUrl.is_primary;
        });
        if (articlePrimaryUrl) {
            window.open(`https://${articlePrimaryUrl.town.domain}/${articlePrimaryUrl.url}`);
        }
    }

    const action = () => {
        let actionButton: React.ReactNode = null;
        if (saveStatusType === "success") {
            actionButton = (<Button color="secondary" size="small" onClick={openArticleInNewWindow}>
                View on website
            </Button>);
        }

        return (
            <React.Fragment>
                {actionButton}
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setSaveStatusOpen(false)}
                >
                    <Close fontSize="small"/>
                </IconButton>
            </React.Fragment>
        );
    };

    const renderPublishedOn = () => {
        if (article.status === 'published' || article.status === 'scheduled') {
            return (<ArticlePublishedOn article={article} publishedOn={String(article.published_on)}></ArticlePublishedOn>)
        }
        return null;
    }

    const handlePublishMenuToggle = () => {
        setPublishMenuOpen((prevOpen) => !prevOpen);
    };

    const handlePublishMenuClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setPublishMenuOpen(false);
    };

    const handleScheduleClick = () => {
        setPublishMenuOpen(false);
        setScheduleDialogOpen(true);
    };

    const handleScheduleSubmit = () => {
        if (!scheduledDate) return;
        
        dispatch(changeArticleField({ 
            status: 'scheduled',
            published_on: scheduledDate.format('Y-MM-DD\\THH:mm:ssZ'),
            shouldSave: true
        }));
        setScheduleDialogOpen(false);
        setScheduledDate(null);
    };

    if (isLoading || (article.id !== articleId && articleId !== "new")) {
        return (<Box className='article-loader'>
            <Typography className='article-loader__heading'>Loading Article</Typography>
            <CircularProgress className='article-loader__progress'/>
        </Box>)
    }

    return (
        <Box>
            <Portal container={sidebarElem}>
                <Stack spacing={2}>
                    <ArticleStatusInfo 
                        status={article.status} 
                        publishedOn={article.published_on}
                        updatedOn={article.updated_on}
                    />
                    {renderPublishedOn()}
                    <ArticleCategory category={article.category}></ArticleCategory>
                    <ArticleTownDetails />
                    <AiToolbox article={article}/>
                </Stack>
            </Portal>
            {renderEditor()}
            <Box sx={{position: 'fixed', right: '20px', bottom: '20px', zIndex: '99'}}>
                <Stack direction="row" spacing={2}>
                    <Collapse in={article.dirty} timeout={300} orientation="horizontal">
                        <Button
                            variant="outlined"
                            onClick={() => dispatch(setDirty(false))}
                            startIcon={<Backspace />}
                            color="inherit"
                        >
                            Reset
                        </Button>
                    </Collapse>
                    <Collapse in={isValid} timeout={300} orientation="horizontal">
                        {renderSaveButton}
                    </Collapse>
                    <Collapse 
                        in={article.status !== 'published' && isValid} 
                        timeout={300} 
                        sx={{
                            height: 36.5,
                        }}
                        orientation="horizontal"
                    >
                        <Box sx={{ 
                            height: 36.5,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <ButtonGroup
                                variant="contained"
                                ref={anchorRef}
                                aria-label="publish button"
                                color="primary"
                            >
                                <Button
                                    onClick={() => {
                                        dispatch(changeArticleField({ 
                                            status: 'published',
                                            published_on: moment().format('Y-MM-DD\\THH:mm:ssZ'),
                                            shouldSave: true
                                        }));
                                    }}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Publish />}
                                    disabled={!isValid}
                                >
                                    Publish Now
                                </Button>
                                <Button
                                    size="small"
                                    aria-controls={publishMenuOpen ? 'split-button-menu' : undefined}
                                    aria-expanded={publishMenuOpen ? 'true' : undefined}
                                    aria-label="select publish option"
                                    aria-haspopup="menu"
                                    onClick={handlePublishMenuToggle}
                                >
                                    <ArrowDropDown />
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Collapse>
                </Stack>
            </Box>
            <Popper
                sx={{
                    zIndex: 1,
                    width: anchorRef.current?.offsetWidth,
                    marginTop: '2px'
                }}
                open={publishMenuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            width: '100%'
                        }}
                    >
                        <Paper sx={{ width: '100%' }}>
                            <ClickAwayListener onClickAway={handlePublishMenuClose}>
                                <MenuList 
                                    id="split-button-menu" 
                                    autoFocusItem
                                    sx={{ width: '100%' }}
                                >
                                    <MenuItem 
                                        onClick={handleScheduleClick}
                                        sx={{ 
                                            width: '100%',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Schedule sx={{ mr: 1 }} />
                                        Schedule
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Dialog 
                open={scheduleDialogOpen} 
                onClose={() => setScheduleDialogOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Schedule Publication</DialogTitle>
                <DialogContent>
                    <StaticDateTimePicker
                        value={scheduledDate || null}
                        onChange={(newValue) => setScheduledDate(newValue)}
                        minDateTime={moment()} 
                        disablePast
                        slotProps={{
                            actionBar: {
                                actions: []
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setScheduleDialogOpen(false)}>Cancel</Button>
                    <Button 
                        onClick={handleScheduleSubmit} 
                        variant="contained" 
                        disabled={!scheduledDate || moment(scheduledDate).isBefore(moment())}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}