import { useEffect } from "react";
import { changeArticleField } from "../features/article/articleSlice";
import { useDispatch } from "react-redux";
import { Paper, Typography, Box } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import Article from "../types/Article";

interface ArticlePublishedOnProps {
    article: Article;
    publishedOn: string | null;
}

export default function ArticlePublishedOn(props: ArticlePublishedOnProps) {
    const { article, publishedOn: dateTime } = props;
    const dispatch = useDispatch();

    const handleChange = (event) => {
        let time = event;
        dispatch(changeArticleField({ published_on: time.format() }));
    };

    useEffect(() => {
        if(dateTime === null) {
            handleChange(moment())
        }
    }, [dateTime])

    const value = dateTime !== null ? moment(dateTime) : moment();
    const isScheduled = article.status === 'scheduled';
    const label = isScheduled ? "Schedule Date" : "Published Date";

    if (!isScheduled && article.status === 'published') {
        return (
            <Paper>
                <Box sx={{ p: 2 }}>
                    <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                        {label}
                    </Typography>
                    <Typography variant="body1">
                        {value.format('DD MMM YYYY, HH:mm')}
                    </Typography>
                </Box>
            </Paper>
        );
    }

    return (
        <Paper>
            <Box sx={{ p: 1 }}>
                <MobileDateTimePicker
                    label={label}
                    value={value}
                    onChange={handleChange}
                    defaultValue={moment()}
                    disabled={!isScheduled}
                    minDateTime={moment()}
                    sx={{ width: "100%" }}
                />
            </Box>
        </Paper>
    );
}
