import {useEffect} from 'react'
import urlSlug from 'url-slug'
import {ObjectID} from "bson";
import {useDispatch, useSelector} from "react-redux";
import {editUrl, changeArticleField} from "../features/article/articleSlice";
import { RootState } from '../app/store';

export default function AutoUrl() {
    const dispatch = useDispatch();
    const article = useSelector((state: RootState) => state.article);

    // Set up initial town from localStorage if needed
    useEffect(() => {
        if (!article.town?.name) {
            try {
                const storedTown = localStorage.getItem('town');
                const storedRegion = localStorage.getItem('region');
                if (storedTown && storedRegion) {
                    const town = JSON.parse(storedTown);
                    const region = JSON.parse(storedRegion);
                    dispatch(changeArticleField({
                        town: {
                            ...town,
                            region: region
                        },
                        dirty: false
                    }));
                }
            } catch (e) {
                console.error('Error parsing town from localStorage:', e);
            }
        }
    }, [dispatch, article.town?.name]);

    // Update URL when headline changes and article is draft
    useEffect(() => {
        if (article.headline && 
            (article.status === 'draft' || article.status === 'scheduled') &&
            !article.published_on &&
            article.town?.name) {
            
            // Check if we need to create/update the URL
            const currentPrimaryUrl = article.url?.find(url => url?.is_primary);
            const newSlug = urlSlug(article.headline);
            
            // Only update if the URL would be different AND we have a valid headline
            if ((!currentPrimaryUrl || currentPrimaryUrl.url !== newSlug) && 
                article.headline.trim() !== '') {
                const newUrl = {
                    id: currentPrimaryUrl?.id || (new ObjectID().toString()),
                    is_primary: true,
                    type: "article",
                    url: newSlug,
                    town: article.town
                };
                
                // Prevent unnecessary dispatches if the URL is the same
                if (!currentPrimaryUrl || currentPrimaryUrl.url !== newUrl.url) {
                    dispatch(editUrl(newUrl));
                }
            }
        }   
    }, [
        article.headline,
        article.status,
        article.town?.name, // Only depend on the town name, not the entire town object
        article.published_on,
        article.url?.length, // Only depend on URL array length changes
        dispatch
    ]);

    return null; // This component doesn't render anything
} 