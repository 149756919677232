import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, Collapse, useTheme} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import {TransitionGroup} from "react-transition-group";
import UrlListItem from "../../ArticleTownDetails/UrlListItem";
import React from "react";
import {changeArticleField} from "../../../features/article/articleSlice";
import {useDispatch} from "react-redux";
import ImageSelector from "../../ImageSelector";


export default function FeaturedImage(props) {
    const article = props.article;
    const dispatch = useDispatch()
    const handleImageChange = (media) => {
        dispatch(changeArticleField({featured_media: media[0]}))
    }
    let theme = useTheme();


    return (
        <Accordion defaultExpanded sx={{maxWidth: "100%"}}>
            <AccordionSummary expandIcon={<ExpandMore/>} sx={{transition:'0.3s ease-in-out all',background:(article.featured_media ? 'inherit' : theme.palette.error.main),
                color:(article.featured_media ? 'inherit' : theme.palette.error.contrastText)}}>
                <Typography>Featured Image</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ImageSelector image={article.featured_media} handleSelect={handleImageChange} />
            </AccordionDetails>
        </Accordion>
    )
}

