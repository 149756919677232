import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStateFromServer } from '../../features/article/articleSlice';
import { useGenerateArticleMutation } from '../../features/api/apiSlice';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Stack,
    Box,
    Typography,
    LinearProgress
} from '@mui/material';

const PROGRESS_MESSAGES = [
    "Scanning the interwebs...",
    "Teaching AI to read...", 
    "Extracting digital goodness...",
    "Making the content local-friendly...",
    "Sprinkling some magic dust...",
    "Asking ChatGPT nicely...",
    "Converting web stuff to news...",
    "Making it EyeOn worthy...",
    "Almost there, just brewing coffee...",
    "Final touches, like a digital artist...",
    "Polishing pixels to perfection...",
    "Consulting the digital oracle...",
    "Translating internet speak...",
    "Adding local context and flavor...",
    "Fact-checking with our AI friends...",
    "Making headlines snappier...",
    "Organizing thoughts coherently...",
    "Ensuring journalistic quality...",
    "Running through final checks...",
    "Getting ready for your review..."
];

// URL validation regex
const URL_REGEX = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

interface UrlProcessingDialogProps {
    open: boolean;
    onClose: () => void;
    articleType: string;
    articleCategory: string;
}

export default function UrlProcessingDialog({ 
    open, 
    onClose,
    articleType,
    articleCategory 
}: UrlProcessingDialogProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [generateArticle] = useGenerateArticleMutation();

    const [url, setUrl] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [error, setError] = useState('');

    const validateUrl = (url: string): boolean => {
        return URL_REGEX.test(url);
    };

    const handleProcessUrl = async () => {
        if (!url) {
            setError('Please enter a URL');
            return;
        }

        if (!validateUrl(url)) {
            setError('Please enter a valid URL (e.g., https://example.com)');
            return;
        }

        try {
            setIsProcessing(true);
            setError('');
            
            const result = await generateArticle({
                targetUrl: url,
                type: articleType,
                category: articleCategory
            }).unwrap();

            // Process the result to remove headline from first HTML block
            const processedResult = {
                ...result,
                page: result.page.map((page, pageIndex) => ({
                    ...page,
                    blocks: page.blocks.map((block, blockIndex) => ({
                        ...block,
                        html: block.type === 'HtmlBlock' && block.html ? (
                            // For first block, remove the headline if present
                            blockIndex === 0 
                                ? block.html
                                    .replace(new RegExp(`<[^>]*>${result.headline}</[^>]*>`, 'g'), '')  // Remove headline with tags
                                    .replace(new RegExp(result.headline, 'g'), '')  // Remove plain headline
                                    .replace(/^(<p>|<h[1-6]>)\s*(<\/p>|<\/h[1-6]>)$/g, '')  // Remove empty paragraphs
                                    .trim()
                                : block.html
                        ) : block.html
                    }))
                }))
            };

            // Update Redux store with the processed article and mark it as AI generated
            dispatch(setStateFromServer({
                ...processedResult,
                isAiGenerated: true // Add flag to indicate this is an AI generated article
            }));
            
            // Navigate to the new article page
            onClose();
            navigate(`/articles/new/${articleType}/${articleCategory}`);

        } catch (err) {
            setError('Failed to process URL. Please try again.');
            console.error('Error generating article:', err);
        } finally {
            setIsProcessing(false);
            setCurrentMessageIndex(0);
        }
    };

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        if (newUrl && !validateUrl(newUrl)) {
            setError('Please enter a valid URL (e.g., https://example.com)');
        } else {
            setError('');
        }
    };

    // Progress message interval
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isProcessing) {
            interval = setInterval(() => {
                setCurrentMessageIndex((prev) => {
                    const nextIndex = prev + 1;
                    if (nextIndex >= PROGRESS_MESSAGES.length) {
                        return 0;
                    }
                    return nextIndex;
                });
            }, 3000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isProcessing]);

    useEffect(() => {
        if (isProcessing) {
            setProgressMessage(PROGRESS_MESSAGES[currentMessageIndex]);
        }
    }, [currentMessageIndex, isProcessing]);

    // Reset state when dialog closes
    useEffect(() => {
        if (!open) {
            setUrl('');
            setError('');
            setIsProcessing(false);
            setCurrentMessageIndex(0);
        }
    }, [open]);

    return (
        <Dialog 
            open={open} 
            onClose={() => !isProcessing && onClose()}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                elevation: 2,
                sx: { borderRadius: 2 }
            }}
        >
            <DialogTitle>
                Create Article from URL
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ mt: 2 }}>
                    {!isProcessing ? (
                        <>
                            <TextField
                                autoFocus
                                label="URL"
                                type="url"
                                fullWidth
                                variant="outlined"
                                value={url}
                                onChange={handleUrlChange}
                                placeholder="https://example.com/article"
                                error={!!error}
                                helperText={error || 'Enter a valid URL starting with http:// or https://'}
                                disabled={isProcessing}
                        
                            />
                        </>
                    ) : (
                        <Box sx={{ width: '100%', py: 3 }}>
                            <Stack spacing={3}>
                                <LinearProgress />
                                <Typography 
                                    variant="body1" 
                                    color="primary"
                                    sx={{ 
                                        textAlign: 'center',
                                        animation: 'fadeIn 0.5s ease-in-out',
                                        '@keyframes fadeIn': {
                                            '0%': {
                                                opacity: 0,
                                                transform: 'translateY(10px)'
                                            },
                                            '100%': {
                                                opacity: 1,
                                                transform: 'translateY(0)'
                                            }
                                        }
                                    }}
                                >
                                    {progressMessage}
                                </Typography>
                            </Stack>
                        </Box>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2, pt: 0 }}>
                <Button 
                    onClick={onClose} 
                    disabled={isProcessing}
                >
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    onClick={handleProcessUrl}
                    disabled={isProcessing || !url || !!error}
                >
                    Create Article
                </Button>
            </DialogActions>
        </Dialog>
    );
} 