import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { changeBlockValidity, changeHtmlBlock, setDirty } from "../../features/article/articleSlice";
import Box from "@mui/material/Box";
import TiptapEditor from '../Editor/TiptapEditor';

export default function HtmlBlock(props) {
    const dispatch = useDispatch();
    const { block, pageId } = props;

    useEffect(() => {
        const isValidHtml = (html: string) => {
            if (!html) return false;
            // Remove HTML tags and whitespace to check if there's actual content
            const textContent = html.replace(/<[^>]*>/g, '').trim();
            return textContent.length > 0;
        };

        dispatch(changeBlockValidity({
            valid: isValidHtml(block.html),
            blockId: block.id,
            pageId: pageId
        }));
    }, [block, pageId, dispatch]);

    const handleChange = (html: string) => {
        dispatch(changeHtmlBlock({
            event: html,
            pageId: pageId,
            blockId: block.id
        }));
    };

    const handleBlur = () => {
        dispatch(setDirty(true));
    };

    return (
        <Box>
            <TiptapEditor
                content={block.html}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </Box>
    );
}