import React, {useRef, useState} from 'react';
import {
    AppBar,
    Autocomplete,
    Button, CircularProgress,
    Dialog,
    DialogContent,
    ImageList,
    Snackbar,
    Stack,
    TablePagination,
    TextField,
    useMediaQuery,
    useTheme,Alert
} from "@mui/material";
import {
    useCreateMediaMutation,
    useGetMediaQuery,
    useGetMediaTagsQuery,
    useGetMediaUploadUrlQuery
} from "../../../features/api/apiSlice";
import Box from "@mui/material/Box";
import {AddPhotoAlternate, Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import EOSImageListItem from "./MediaList/EOSImageListItem";
import axios from "axios";
import {useSelector} from "react-redux";
import { RootState } from '../../../app/store';

export default function MediaList(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [uploading, setUploading] = useState(false);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedImages, setSelectedImages] = useState(props.value);
    const [fileNameSearch, setFileNameSearch] = useState('');
    const [fileNameSearchInput, setFileNameSearchInput] = useState('');
    const {data, isSuccess} = useGetMediaQuery({limit: rowsPerPage, page: page, tags: selectedTags, search: fileNameSearch})
    const uploadLink = useGetMediaUploadUrlQuery({});
    const [createMedia] = useCreateMediaMutation()
    const tags = useGetMediaTagsQuery({tags: selectedTags});
    const theme = useTheme();
    const medium = useMediaQuery(theme.breakpoints.down('xl'));
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const fileInputRef = useRef<HTMLInputElement>(null);
    const fileInputForm = useRef<HTMLFormElement>(null);
    const multipleImageSelect = props.multiple ?? false;
    const auth = useSelector((state: RootState) => state.auth)
    let cols = 5;
    if (medium) {
        cols = 4;
    }
    if (small) {
        cols = 2;
    }
    if (xs) {
        cols = 1;
    }


    const [additionalTagsOpen, setAdditionalTagsOpen] = useState(false);

    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleTagDelete(tag) {
        setSelectedTags(selectedTags.filter(item => item !== tag));
    }

    function handleTagChange(event, selected, reason) {
        console.log(event);
        console.log(reason);
        console.log(selected);
        if(reason === 'selectOption') {
            setSelectedTags(selected);
        }
        if(reason === 'clear') {
            setSelectedTags([]);
        }
    }

    const selectImage = (media) => {
        if(selectedImages.includes(media)) {
            setSelectedImages(selectedImages.filter(item => item !== media))
            return;
        }
        if(multipleImageSelect) {
            setSelectedImages([...selectedImages, media])
        } else {
            setSelectedImages([media]);
        }
    }

    const imageIsSelected = (media) => {
        return selectedImages.includes(media);
    }

    const handleSearchSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setFileNameSearch(event.target.value)
        }
    }


    const renderTags = () => {
        if (tags.isSuccess) {
            return (
                <Paper elevation={2} sx={{padding:'10px'}}>
                    <Stack direction={{xs:'column', md:'row'}} spacing={2}>
                        <Autocomplete<string, true>
                            multiple
                            limitTags={2}
                            sx={{
                                minWidth:`calc(100%/${cols})`
                            }}
                            open={additionalTagsOpen}
                            onOpen={() => {
                                setAdditionalTagsOpen(true);
                            }}
                            onClose={() => {
                                setAdditionalTagsOpen(false);
                            }}
                            options={tags.data?.map(s => s["_id"]) || []}
                            value={selectedTags}
                            renderTags={(value, getTagProps) =>
                                value.map((option) => (
                                    <Chip sx={{margin:'5px'}} key={`tag-${option}`} label={option} color="primary" onDelete={() => handleTagDelete(option)} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Tags"
                                    size="small"
                                    placeholder="police"
                                />
                            )}
                            onChange={handleTagChange}
                        />
                        <TextField label="File Name" size="small" variant="outlined" key="file-name-search-input" onChange={(event) => setFileNameSearchInput(event.target.value)} value={fileNameSearchInput} onKeyDown={handleSearchSubmit} />
                    </Stack>
                </Paper>

            )
        }
        return (<Box>Tags Loading</Box>)
    }

    const renderMedia = (media) => {
        return (
            <EOSImageListItem key={media.id} media={media} selectImage={selectImage} imageIsSelected={imageIsSelected} />
        )
    }

    const renderMediaSelected = (media) => {
        if(media) {
            return (
                <Box sx={{display: {xs:'none', md:'inline-block'}, height:'70px'}} component="img" key={media.id} referrerPolicy="no-referrer" src={media.renditions.thumbnail.href} />
            )
        }
    }

    const handleUploadClick = () => {
        uploadLink.refetch();
        fileInputRef.current?.click();
    }

    const handleUploadChange = async (event) => {
        if(event.target.value) {
            setUploading(true);
           await uploadImage(event.target.files[0]).then(createMedia);
            setUploading(false);
        }
    }

    const uploadImage = async file => {
        const formData = new FormData();
        formData.append("file", file);
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "Authorization": "Bearer " + auth.accessToken
            }
        };
        return await axios.post(process.env.REACT_APP_API_URL + '/media', formData, config);
    }

    const renderUploadForm = () => {
        if(uploadLink.isSuccess) {
            return (
                <form method="post" encType="multipart/form-data" action={ process.env.REACT_APP_API_URL + '/media'}
                      ref={fileInputForm}>
                    <input hidden accept="image/jpeg,image/png,image/gif,image/webp" type="file" name="file" ref={fileInputRef} onChange={handleUploadChange}/>
                </form>
            )
        }
    }
    const renderUploadIcon = () => {
        let icon = <AddPhotoAlternate sx={{ fontSize: 24 }} />
        let text = "Upload new image";
        if(uploading) {
            icon = <CircularProgress color="inherit" size={24} sx={{padding:'15px'}}  />
            text = "Uploading..."
        }

        return (
            <Stack direction="row" justifyContent="center" sx={{margin:'auto'}} alignItems="center" spacing={2}>
                {icon}
                <Typography>{text}</Typography>
            </Stack>
        )
    }

    const renderInside = () => {
        if (isSuccess) {
            return (
                <Box>
                    <Stack spacing={2}>
                    {renderTags()}
                        <Stack component={Button} disabled={uploading} color="secondary" variant="contained"  sx={{height:'100%', border:'4px solid transparent'}} direction="row" justifyContent="center" alignItems="center" onClick={handleUploadClick}>
                            {renderUploadIcon()}
                            {renderUploadForm()}
                        </Stack>
                    </Stack>
                    <ImageList cols={cols} rowHeight={250} gap={10} sx={{height: {xs: 'calc(100vh - 450px)', md:  'calc(100vh - 350px)'}}}>
                        {data.results.map((media) => renderMedia(media))}
                    </ImageList>
                    <Stack spacing={2} direction={{xs: 'column', md:'row'}} justifyContent="flex-end" alignItems="center">
                        {selectedImages.map((media) => renderMediaSelected(media))}
                        <TablePagination
                            component="div"
                            count={data.count}
                            page={data.currentPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Media per page:"
                            rowsPerPageOptions={[25, 50, 75, 100]}
                        />
                        <Button variant="contained" disabled={(selectedImages.length === 0 || (selectedImages.length !== 0 && !selectedImages[0].description.caption && selectedImages[0].description.tags.length === 0)) } onClick={() => props.onSelect(selectedImages)}>Use Image</Button>
                        <Snackbar open={(selectedImages.length !== 0 && !selectedImages[0].description.caption && selectedImages[0].description.tags.length === 0)} anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}>
                            <Alert severity="error" sx={{ width: '100%' }}>
                                Chosen image must have a caption and tags
                            </Alert>
                        </Snackbar>
                    </Stack>

                </Box>

            )
        }
        return (<div>Loading</div>)
    }

    return (
        <Dialog fullScreen open={props.open}  onClose={props.onClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Image Manager
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {renderInside()}
            </DialogContent>
        </Dialog>
    )

}
