import { useState } from 'react';
import {ListItem} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
    Edit as EditIcon,
    CallSplit as RedirectIcon,
    Home as HomeIcon,
    OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";

import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    Alert,
    Tooltip,
    Chip,
    Paper
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { editUrl } from '../../features/article/articleSlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function UrlListItem({url, onEdit}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const dispatch = useDispatch();
    const [redirectDialog, setRedirectDialog] = useState(false);
    const [redirectType, setRedirectType] = useState(301);

    const handleRedirectToHomepage = () => {
        dispatch(editUrl({
            ...url,
            redirect_to: `https://${url.town.domain}`,
            redirect_type: redirectType
        }));
        setRedirectDialog(false);
    };

    const handleOpenUrl = () => {
        window.open(`https://${url.town.domain}/${url.url}`, '_blank');
    };

    return (
        <>
            <Paper 
                elevation={0}
                sx={{
                    mb: 1,
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: url.is_primary ? 'primary.main' : 'divider',
                    bgcolor: url.is_primary ? 'primary.50' : 'transparent',
                    '&:hover': {
                        bgcolor: url.is_primary ? 'primary.100' : 'action.hover',
                        borderColor: 'primary.main',
                    },
                    transition: 'all 0.2s ease-in-out',
                }}
            >
                <ListItem
                    sx={{
                        pr: { xs: 2, sm: 2, md: 2 },
                        flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
                        py: { xs: 1.5, sm: 1.5, md: 1 },
                    }}
                    secondaryAction={
                        <Stack 
                            direction="row" 
                            spacing={1}
                            sx={{
                                position: { xs: 'relative', sm: 'relative', md: 'absolute' },
                                right: { md: 8 },
                                top: { md: '50%' },
                                transform: { md: 'translateY(-50%)' },
                                mt: { xs: 1, sm: 1, md: 0 },
                                ml: { xs: 0, sm: 0, md: 0 }
                            }}
                        >
                            <Tooltip title="Open URL">
                                <IconButton 
                                    onClick={handleOpenUrl}
                                    size={(isMobile || isTablet) ? "medium" : "small"}
                                    sx={{
                                        bgcolor: 'success.main',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: 'success.dark',
                                        },
                                        '& svg': { 
                                            fontSize: (isMobile || isTablet) ? '1.2rem' : '1rem' 
                                        }
                                    }}
                                >
                                    <OpenInNewIcon />
                                </IconButton>
                            </Tooltip>
                            {url.town?.domain && (
                                <Tooltip title="Redirect to Homepage">
                                    <IconButton 
                                        onClick={() => setRedirectDialog(true)}
                                        size={(isMobile || isTablet) ? "medium" : "small"}
                                        sx={{
                                            bgcolor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                                bgcolor: 'primary.dark',
                                            },
                                            '& svg': { 
                                                fontSize: (isMobile || isTablet) ? '1.2rem' : '1rem' 
                                            }
                                        }}
                                    >
                                        <HomeIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Edit URL">
                                <IconButton 
                                    onClick={() => onEdit(url)}
                                    size={(isMobile || isTablet) ? "medium" : "small"}
                                    sx={{
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        '&:hover': {
                                            bgcolor: 'action.hover',
                                            borderColor: 'text.primary',
                                        },
                                        '& svg': { 
                                            fontSize: (isMobile || isTablet) ? '1.2rem' : '1rem' 
                                        }
                                    }}
                                >
                                    <EditIcon />
            </IconButton>
                            </Tooltip>
                        </Stack>
        }
    >
        <ListItemText
                        sx={{
                            m: 0,
                            flex: 1,
                            minWidth: 0,
                            width: '100%',
                            maxWidth: { xs: '100%', sm: '100%', md: 'calc(100% - 120px)' }
                        }}
                        primary={
                            <Stack spacing={0.5} sx={{ width: '100%' }}>
                                {url.redirect_to && (
                                    <Stack 
                                        direction={{ xs: 'column', sm: 'column', md: 'row' }} 
                                        spacing={1} 
                                        alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
                                    >
                                        <Chip
                                            icon={<RedirectIcon sx={{ fontSize: '1rem' }} />}
                                            label={url.redirect_type === 301 ? "Permanent Redirect" : "Temporary Redirect"}
                                            size="small"
                                            color={url.redirect_type === 301 ? "secondary" : "default"}
                                            variant="outlined"
                                            sx={{ 
                                                height: (isMobile || isTablet) ? '24px' : '20px',
                                                '& .MuiChip-label': {
                                                    px: 1,
                                                    fontSize: (isMobile || isTablet) ? '0.8rem' : '0.7rem',
                                                    fontWeight: 500
                                                }
                                            }}
                                        />
                                        <Typography 
                                            variant="caption" 
                                            sx={{ 
                                                color: 'text.secondary',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5,
                                                fontSize: (isMobile || isTablet) ? '0.85rem' : '0.75rem'
                                            }}
                                        >
                                            to <span style={{ fontFamily: 'monospace' }}>{url.redirect_to}</span>
                                        </Typography>
                                    </Stack>
                                )}
                                <Typography 
                                    component="div" 
                                    variant="body2"
                                    sx={{
                                        fontFamily: 'monospace',
                                        fontSize: (isMobile || isTablet) ? '1rem' : '0.875rem',
                                        fontWeight: url.is_primary ? 600 : 400,
                                        color: url.redirect_to ? 'text.secondary' : url.is_primary ? 'primary.main' : 'text.primary',
                                        textDecoration: url.redirect_to ? 'line-through' : 'none',
                                        wordBreak: 'break-all'
                                    }}
                                >
                                    {url.url}
                                </Typography>
                            </Stack>
                        }
                        secondary={
                            <Typography 
                                variant="caption" 
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    mt: 0.5,
                                    color: 'text.secondary',
                                    fontSize: (isMobile || isTablet) ? '0.85rem' : '0.75rem'
                                }}
                            >
                                {url.town?.name && url.town?.region?.name ? (
                                    <>
                                        {url.town.name}
                                        <Typography 
                                            component="span" 
                                            sx={{ 
                                                color: 'text.disabled',
                                                fontSize: 'inherit',
                                                lineHeight: 0
                                            }}
                                        >
                                            •
                                        </Typography>
                                        {url.town.region.name}
                                    </>
                                ) : (
                                    'Loading town information...'
                                )}
                            </Typography>
                        }
                    />
                </ListItem>
            </Paper>

            <Dialog 
                open={redirectDialog} 
                onClose={() => setRedirectDialog(false)}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    elevation: 2,
                    sx: { 
                        borderRadius: 2,
                        margin: { xs: 2, sm: 2, md: 'auto' },
                        maxWidth: { sm: '400px' }
                    }
                }}
            >
                <DialogTitle sx={{ pb: 1 }}>
                    Redirect to Homepage
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        <Alert 
                            severity="info" 
                            icon={<HomeIcon />}
                            sx={{
                                borderRadius: 1,
                                '& .MuiAlert-icon': {
                                    color: 'primary.main'
                                }
                            }}
                        >
                            This URL will redirect to <strong>{url.town?.domain}</strong>
                        </Alert>
                        <FormControl fullWidth>
                            <InputLabel>Redirect Type</InputLabel>
                            <Select
                                value={redirectType}
                                label="Redirect Type"
                                onChange={(e) => setRedirectType(Number(e.target.value))}
                                sx={{ borderRadius: 1 }}
                            >
                                <MenuItem value={301}>Permanent (301) - Long-term change</MenuItem>
                                <MenuItem value={302}>Temporary (302) - Short-term redirect</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 2, pt: 1 }}>
                    <Button 
                        onClick={() => setRedirectDialog(false)}
                        sx={{ borderRadius: 1 }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleRedirectToHomepage}
                        color="primary"
                        startIcon={<HomeIcon />}
                        sx={{ borderRadius: 1 }}
                    >
                        Set Redirect
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}