import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {changeBlockValidity, changeImageInBlock} from "../../features/article/articleSlice";
import ImageSelector from "../ImageSelector";



function CloseIcon() {
    return null;
}

export default function ImageBlock(props) {

    const image = props.block.image;
    const dispatch = useDispatch();


    const handleSelect = (media) => {
        dispatch(changeImageInBlock({
            media: media[0],
            blockId: props.block.id,
            pageId: props.pageId
        }))
    }

    useEffect(() => {
        const isValidImage = (image: any) => {
            if (!image) return false;
            if (!image.description) return false;
            if (!image.description.caption) return false;
            if (!image.description.tags || image.description.tags.length === 0) return false;
            return true;
        };

        dispatch(changeBlockValidity({
            valid: isValidImage(props.block.image),
            blockId: props.block.id,
            pageId: props.pageId
        }));
    }, [props.block.image, props.block.id, props.pageId, dispatch]);



    return (<ImageSelector image={image} handleSelect={handleSelect} /> );
}