import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {changeBlockValidity, changeEmbedBlock, setDirty} from "../../features/article/articleSlice";

export default function EmbedBlock(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        const isValidEmbed = (html: string) => {
            if (!html) return false;
            // Check if it contains an iframe, blockquote, or script tag
            const hasEmbedTag = /<(iframe|blockquote|script)[^>]*>/i.test(html);
            return hasEmbedTag && html.trim().length > 0;
        };

        dispatch(changeBlockValidity({
            valid: isValidEmbed(props.block.html),
            blockId: props.block.id,
            pageId: props.pageId
        }));
    }, [props.block.html, props.block.id, props.pageId, dispatch]);

    return (
        <div>
        <div dangerouslySetInnerHTML={{__html: props.block.html}}/>
    <textarea
        key={props.block.id}
        style={{
                width: '100%', // Full width
                height: '200px', // Set height
                padding: '12px 20px', // Padding
                boxSizing: 'border-box', // Allow padding and border to be included in total width and height
                border: '2px solid #ccc', // Border
                backgroundColor: '#f8f8f8', // Background color
                fontSize: '16px', // Text size
                resize: 'none' // Disable resizing
            }}
            placeholder={'Paste your embed code here'}
            value={props.block.html}
            onChange={(e) => dispatch(changeEmbedBlock({
                event: e.target.value,
                pageId: props.pageId,
                blockId: props.block.id
            }))}
            onBlur={() => dispatch(setDirty(true))}
        />
        </div>
    );
}