import {
    Box,
    Paper,
    useTheme
} from "@mui/material";
import React, {useEffect} from "react";
import List from "@mui/material/List";
import UrlListItem from "./ArticleTownDetails/UrlListItem";
import EditUrl from "./EditUrl";
import AutoUrl from "./AutoUrl";
import {useDispatch, useSelector} from "react-redux";
import {setIsValid} from "../features/article/articleSlice";
import {TransitionGroup} from "react-transition-group";
import { Collapse } from "@mui/material";
import { RootState } from "../app/store";
import Url from "../types/Url";

export default function ArticleTownDetails() {
    const theme = useTheme();
    const article = useSelector((state: RootState) => state.article);
    const dispatch = useDispatch();
    const [editingUrl, setEditingUrl] = React.useState<Url | null>(null);

    // Get primary and other URLs directly from article
    const isUrl = (url: any): url is Url => url && typeof url.is_primary !== 'undefined';
    const primaryUrl = article.url?.find(url => isUrl(url) && url.is_primary);
    const otherUrls = article.url?.filter(url => isUrl(url) && !url.is_primary) || [];

    // Update URL validity - URL is valid if we have a primary URL with both URL and town
    useEffect(() => {
        const isValid = !!(primaryUrl?.url && primaryUrl?.town?.name);
        dispatch(setIsValid({url: isValid}));
    }, [primaryUrl, dispatch]);

    const handleEditClose = () => {
        setEditingUrl(null);
    };

    return (
        <>
            <AutoUrl />
            <Paper 
                elevation={0} 
                sx={{
                    borderRadius: 2,
                    overflow: 'hidden',
                    border: '1px solid',
                    borderColor: !article.valid.url ? 'error.light' : theme.palette.divider,
                    transition: 'border-color 0.2s ease-in-out',
                    bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'grey.50',
                }}
            >
                <Box sx={{ p: { xs: 1.5, sm: 2 } }}>
                    <List disablePadding>
                        <TransitionGroup>
                            {primaryUrl ? (
                                <Collapse key="primary-url">
                                    <UrlListItem 
                                        url={primaryUrl} 
                                        onEdit={() => setEditingUrl(primaryUrl as Url)}
                                    />
                                </Collapse>
                            ) : article.status === 'draft' ? (
                                <Collapse key="draft-message">
                                    <Box 
                                        sx={{ 
                                            p: 2, 
                                            borderRadius: 1,
                                            bgcolor: 'background.paper',
                                            border: '1px dashed',
                                            borderColor: !article.valid.url ? 'error.light' : 'divider',
                                            color: 'text.secondary',
                                            textAlign: 'center',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        {!article.headline
                                            ? "Add a headline to generate the URL"
                                            : ''}
                                    </Box>
                                </Collapse>
                            ) : null}
                            {otherUrls.map(url => url && (
                                <Collapse key={url.id}>
                                    <UrlListItem 
                                        url={url} 
                                        onEdit={() => setEditingUrl(url)}
                                    />
                                </Collapse>
                            ))}
                        </TransitionGroup>
                    </List>
                </Box>
            </Paper>

            {editingUrl && (
                <EditUrl 
                    url={editingUrl}
                    onClose={handleEditClose}
                />
            )}
        </>
    );
}