import React from 'react';
import { useGetArticlesQuery } from "../features/api/apiSlice";
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    IconButton,
    Stack,
    Chip,
    useTheme,
    Paper,
    Tooltip,
} from "@mui/material";
import { Edit, Schedule, ContentCopy } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clear } from "../features/article/articleSlice";
import RenderStatusChip from "../renderers/RenderStatusChip";

export default function ScheduledArticles({ town, category }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    
    const { data, isLoading } = useGetArticlesQuery({
        town: town,
        limit: 100, // Show more scheduled articles since they're important
        page: 0,
        category: category,
        status: 'scheduled',
        url: true
    }, {
        pollingInterval: 30000 // Poll every 30 seconds to keep scheduled articles up to date
    });

    const handleEditClick = (articleId) => {
        dispatch(clear({}));
        navigate('/articles/' + articleId);
    };

    const handleCopyLink = (article) => {
        // Check if url is an array and has items
        if (Array.isArray(article.url) && article.url.length > 0) {
            const primaryUrl = article.url.find(url => url.is_primary);
            if (primaryUrl?.town?.domain && primaryUrl.url) {
                const fullUrl = `https://${primaryUrl.town.domain}/${primaryUrl.url}`;
                navigator.clipboard.writeText(fullUrl);
            }
        }
    };

    if (isLoading) {
        return null;
    }

    if (!data?.results?.length) {
        return null;
    }

    return (
            <List component={Paper} sx={{ width: '100%' }}>
                {data.results.map((article) => {
                    const scheduledDate = moment(article.published_on);
                    const isToday = scheduledDate.isSame(moment(), 'day');
                    const isTomorrow = scheduledDate.isSame(moment().add(1, 'day'), 'day');
                    
                    let scheduleText = scheduledDate.format('DD-MM-YYYY HH:mm');
                    if (isToday) {
                        scheduleText = `Today at ${scheduledDate.format('HH:mm')}`;
                    } else if (isTomorrow) {
                        scheduleText = `Tomorrow at ${scheduledDate.format('HH:mm')}`;
                    }

                    // Check if url is an array and has items
                    const hasUrl = Array.isArray(article.url) && 
                                 article.url.length > 0 && 
                                 article.url.some(url => url.is_primary && url.town?.domain && url.url);

                    return (
                        <ListItem
                            key={article.id}
                            sx={{
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                transition: 'background-color 0.2s',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                },
                                '&:last-child': {
                                    borderBottom: 'none',
                                }
                            }}
                            secondaryAction={
                                <Stack direction="row" spacing={1}>
                                    {hasUrl && (
                                        <Tooltip title="Copy URL">
                                            <IconButton 
                                                edge="end" 
                                                onClick={() => handleCopyLink(article)}
                                                sx={{
                                                    bgcolor: 'success.main',
                                                    color: 'white',
                                                    '&:hover': {
                                                        bgcolor: 'success.dark',
                                                    }
                                                }}
                                            >
                                                <ContentCopy />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <IconButton 
                                        edge="end" 
                                        onClick={() => handleEditClick(article.id)}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.main + '20'
                                            }
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Stack>
                            }
                        >
                            <ListItemText
                                primary={
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography variant="h6" component="span">
                                            {article.headline}
                                        </Typography>
                                        <RenderStatusChip value={article.status} />
                                        <Chip 
                                            icon={<Schedule />}
                                            label={scheduleText}
                                            color={isToday ? "error" : isTomorrow ? "warning" : "default"}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Stack>
                                }
                                secondary={
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        Last updated: {moment(article.updated_on).format('DD-MM-YYYY HH:mm')}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
    );
} 