import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {changeArticleField} from "../features/article/articleSlice";
import {useDispatch} from "react-redux";
import Paper from "@mui/material/Paper";

export default function ArticleCategory(props) {

    const category = props.category
    const dispatch = useDispatch()

    const handleChange = (event) => {
        dispatch(changeArticleField({category: event.target.value}))
    }

    return (
        <Paper>
            <FormControl fullWidth>
                <InputLabel id="article-category-label">Article Category</InputLabel>
                <Select
                    labelId="article-category-label"
                    id="article-category"
                    value={category}
                    label="Article Category"
                    onChange={handleChange}
                >
                    <MenuItem value="news">News</MenuItem>
                    <MenuItem value="lifestyle">Lifestyle</MenuItem>
                    <MenuItem value="events">Events</MenuItem>
                </Select>
            </FormControl>
        </Paper>
    )
}