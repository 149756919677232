import moment from "moment";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button} from "@mui/material";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import {useEffect, useState} from "react";

export default function UpdatePageDateDialog({ open, handleClose, handleDateChange, handleDelete, selectedPage, pages }) {
    const [selectedDate, setSelectedDate] = useState(moment());
    useEffect(() => {
        if (selectedPage) {
            const page = pages.find(page => page.id === selectedPage);
            if (page) {
                setSelectedDate(moment(page.update_date));
            }
        }
    }, [selectedPage, pages, open])


    const handleDateSave = () => {
        handleDateChange(selectedDate.format())
        handleClose();
    }
    const handleDateChangeLocal = (date) => {
        setSelectedDate(date);
    };

    const handleDeleteLocal = () => {
        handleDelete(selectedPage);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Change Update Date/Time</DialogTitle>
            <DialogContent>
                <MobileDateTimePicker
                    label="Update Date"
                    value={selectedDate}
                    onChange={handleDateChangeLocal}
                    slots={{
                        textField: TextField
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteLocal} color="error">Delete Page</Button>
                <Button onClick={handleClose} color="secondary">Cancel</Button>
                <Button onClick={handleDateSave} color="primary" variant="outlined">Save</Button>
            </DialogActions>
        </Dialog>
    );
}