import React, {useEffect} from 'react'
import Paper from "@mui/material/Paper";
import {Stack, TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import {changeArticleField, setIsValid} from "../../features/article/articleSlice";
import FeaturedImage from "./Fields/FeaturedImage";


export default function Event(props) {
    let article = props.article;
    let dispatch = useDispatch();

    useEffect(() => {
        let isValid = false;
        if(article.headline && article.featured_media) {
            isValid = true
        }
        dispatch(setIsValid({fields: isValid}));
    }, [article, dispatch])

    const handleHeadlineChange = (event) => {
        dispatch(changeArticleField({headline: event.target.value}))
    }

    return (
        <Stack spacing={2}>
            <Paper sx={{p:2}}>
                <TextField fullWidth label="Headline" id="headline" value={article.headline} onChange={handleHeadlineChange} />
            </Paper>
            <FeaturedImage article={article} />
        </Stack>
    )
}