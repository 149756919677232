import React from 'react';
import {defaultAnimateLayoutChanges, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import Paper from "@mui/material/Paper";
import {Delete, DragIndicator, MoreVert} from "@mui/icons-material";
import Box from "@mui/material/Box";

import HtmlBlock from './Blocks/HtmlBlock';
import ImageBlock from './Blocks/ImageBlock';
import VideoBlock from './Blocks/VideoBlock';
import {Menu, MenuItem, Stack, useTheme} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {useDispatch, useSelector} from 'react-redux'
import {removeBlockFromPage} from "../features/article/articleSlice";
import Typography from "@mui/material/Typography";
import EmbedBlock from "./Blocks/EmbedBlock";

const Components = {
    HtmlBlock: HtmlBlock,
    ImageBlock: ImageBlock,
    VideoBlock: VideoBlock,
    EmbedBlock: EmbedBlock
}

function animateLayoutChanges(args) {
    const {isSorting, wasSorting} = args;

    if (isSorting || wasSorting) {
        return defaultAnimateLayoutChanges(args);
    }

    return true;
}

export default function Block(props) {
    const dispatch = useDispatch();
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({animateLayoutChanges, id: props.id});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const theme = useTheme()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = () => {
        setAnchorEl(null);
        dispatch(removeBlockFromPage({
            blockIndex: props.blockIndex,
            pageId: props.pageId
        }))
    };


    return (
        <Paper elevation={2} ref={setNodeRef} style={style} {...attributes} sx={{
            position:'relative'
        }} >
            <Stack direction="row"   className='block-header-bar' justifyContent="flex-end" p={1.5} sx={{
                zIndex:'98',
                background:(props.block.valid !== false ? 'inherit' : theme.palette.error.main),
                color:(props.block.valid !== false ? 'inherit' : theme.palette.error.contrastText),
                transition: 'all 0.5s ease-in-out'
               
            }} >
                <Typography sx={{alignSelf: 'flex-start',flexGrow:'1', fontWeight:'bold'}}>{(props.block.valid === false ? 'Please complete or delete this block' : '') }</Typography>
                <MoreVert  onClick={handleClick}  sx={{cursor:'pointer'}} />
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </Menu>
                <DragIndicator sx={{cursor:'grab'}} {...listeners} />
            </Stack>
            <Box component={Components[props.block.type]} sx={{position:'relative'}} {...props}></Box>
        </Paper>
    );
}