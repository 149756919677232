import * as React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { useTheme } from '@mui/material';
import { 
    Schedule as ScheduleIcon,
    Publish as PublishIcon,
    Edit as EditIcon,
} from '@mui/icons-material';

export default function RenderStatusChip(params) {
    const status = params.value ?? '';
    const theme = useTheme();
    
    const getChipProps = () => {
        switch(status) {
            case 'scheduled':
                return {
                    icon: <ScheduleIcon />,
                    color: 'warning' as ChipProps['color'],
                    label: 'Scheduled',
                    sx: {
                        '& .MuiChip-icon': {
                            animation: 'spin 2s linear infinite',
                        },
                        '@keyframes spin': {
                            '0%': { transform: 'rotate(0deg)' },
                            '100%': { transform: 'rotate(360deg)' }
                        }
                    }
                };
            case 'published':
                return {
                    icon: <PublishIcon />,
                    color: 'success' as ChipProps['color'],
                    label: 'Published',
                    sx: {
                        '& .MuiChip-icon': {
                            transition: 'transform 0.3s ease-in-out',
                        },
                        '&:hover .MuiChip-icon': {
                            transform: 'translateY(-3px)'
                        }
                    }
                };
            default:
                return {
                    icon: <EditIcon />,
                    color: 'default' as ChipProps['color'],
                    label: 'Draft',
                    sx: {
                        '& .MuiChip-icon': {
                            transition: 'transform 0.3s ease-in-out',
                        },
                        '&:hover .MuiChip-icon': {
                            transform: 'rotate(-45deg)'
                        }
                    }
                };
        }
    };

    const chipProps = getChipProps();

    return (
        <Chip
            {...chipProps}
            variant="filled"
            size="small"
            sx={{
                fontWeight: 500,
                ...chipProps.sx,
                '& .MuiChip-label': {
                    px: 1
                }
            }}
        />
    );
}