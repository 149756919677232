import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

// Default toast configuration
export const toastConfig: ToastContainerProps = {
    position: "bottom-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
};

// ToastContainer with default configuration
export const AppToastContainer: React.FC = () => (
    <ToastContainer {...toastConfig} />
); 