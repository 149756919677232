import React, {useEffect, useState} from "react";
import ArticleTable from "../components/ArticleTable";
import ScheduledArticles from "../components/ScheduledArticles";
import {
    Backdrop, 
    SpeedDial, 
    SpeedDialAction, 
    SpeedDialIcon, 
    Stack, 
    Tab, 
    Typography,
    useMediaQuery, 
    useTheme,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    Paper,
    Box,
    Button
} from "@mui/material";
import {Article, Link as LinkIcon, NoteAdd} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import TownSelector from "../components/ArticleTownDetails/TownSelector";
import {useLocalStorage} from "../app/hooks";
import {clear} from "../features/article/articleSlice";
import {useDispatch} from "react-redux";
import {TabContext, TabList} from "@mui/lab";
import { useGetArticlesQuery } from "../features/api/apiSlice";
import UrlProcessingDialog from "../components/Ai/UrlProcessingDialog";

const PROGRESS_MESSAGES = [
    "Scanning the interwebs...",
    "Teaching AI to read...",
    "Extracting digital goodness...",
    "Making the content local-friendly...",
    "Sprinkling some magic dust...",
    "Asking ChatGPT nicely...",
    "Converting web stuff to news...",
    "Making it EyeOn worthy...",
    "Almost there, just brewing coffee...",
    "Final touches, like a digital artist..."
];

// URL validation regex
const URL_REGEX = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export default function ArticleList() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
    const [town, setTown] = useLocalStorage("initialTown", '')
    const [category, setCategory] = useLocalStorage("category", 'news')
    const [articleCategory, setArticleCategory] = useState(category)
    const [articleType, setArticleType] = useState('standard')
    const [urlDialogOpen, setUrlDialogOpen] = useState(false);
    const dispatch = useDispatch();

    // Query for scheduled articles
    const { data: scheduledData } = useGetArticlesQuery({
        town: town,
        limit: 100,
        page: 0,
        category: category,
        status: 'scheduled'
    });

    const hasScheduledArticles = scheduledData?.results?.length > 0;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const handleCreate = (type, choseCategory) => {
        dispatch(clear({}));
        navigate('/articles/new/' + type + '/' + choseCategory)
    }
    
    const handleFabCreate = () => {
        setCreateDialogOpen(true);
    }

    const handleCreateBlankArticle = () => {
        setCreateDialogOpen(false);
        handleCreate(articleType, articleCategory);
    }

    const handleCreateFromUrl = () => {
        setCreateDialogOpen(false);
        setUrlDialogOpen(true);
    };

    const handleTownSelect = (value) => {
        setTown(value.slug)
    }

    const handleCategoryChange = (event, newValue) => {
        setCategory(newValue);
    };

    useEffect(() => {
        if (category) {
            if (category === 'events') {
                setArticleType('event')
            } else {
                setArticleType('standard')
            }
            setArticleCategory(category)
        }
    }, [category]);

    return (
        <Box sx={{width:'100%'}}>
            <Stack spacing={3}>
                <Box>
                    <TownSelector direction="row" onSelect={handleTownSelect}></TownSelector>
                </Box>
                <Box>
                    <TabContext value={category}>
                        <TabList onChange={handleCategoryChange} aria-label="Category tabs">
                            <Tab label="News" value='news'/>
                            <Tab label="Events" value='events'/>
                            <Tab label="Lifestyle" value='lifestyle'/>
                        </TabList>
                    </TabContext>
                </Box>
                
                {hasScheduledArticles && (
                    <>
                        <Box>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium', }}>
                                Scheduled Articles
                            </Typography>
                            <ScheduledArticles town={town} category={category} />
                        </Box>
                        <Divider />
                    </>
                )}

                <Box>
                    <ArticleTable town={town} category={category} />
                </Box>
            </Stack>

            <Dialog 
                open={createDialogOpen} 
                onClose={() => setCreateDialogOpen(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    elevation: 2,
                    sx: { borderRadius: 2 }
                }}
            >
                <DialogTitle>
                    Create New Article
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        <Paper
                            variant="outlined"
                            sx={{
                                p: 2,
                                cursor: 'pointer',
                                transition: 'all 0.2s',
                                '&:hover': {
                                    borderColor: 'primary.main',
                                    bgcolor: 'action.hover'
                                }
                            }}
                            onClick={handleCreateBlankArticle}
                        >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <NoteAdd color="primary" sx={{ fontSize: 40 }} />
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        Blank Article
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Start with a blank article and add content manually
                                    </Typography>
                                </Box>
                            </Stack>
                        </Paper>
                        <Paper
                            variant="outlined"
                            sx={{
                                p: 2,
                                cursor: 'pointer',
                                transition: 'all 0.2s',
                                '&:hover': {
                                    borderColor: 'primary.main',
                                    bgcolor: 'action.hover'
                                }
                            }}
                            onClick={handleCreateFromUrl}
                        >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <LinkIcon color="primary" sx={{ fontSize: 40 }} />
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        Create from URL
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Import content from an existing webpage
                                    </Typography>
                                </Box>
                            </Stack>
                        </Paper>
                    </Stack>
                </DialogContent>
            </Dialog>

            <UrlProcessingDialog 
                open={urlDialogOpen}
                onClose={() => setUrlDialogOpen(false)}
                articleType={articleType}
                articleCategory={articleCategory}
            />

            <Backdrop open={open} sx={{zIndex: theme.zIndex.drawer + 2}}/>
            <SpeedDial
                ariaLabel="Create"
                sx={{position: 'fixed', bottom: 16, right: 16, zIndex: theme.zIndex.drawer + 3}}
                icon={<SpeedDialIcon/>}
                onClose={handleClose}
                onOpen={handleOpen}
                onClick={handleFabCreate}
                open={open}
            >
                <SpeedDialAction
                    key="article"
                    icon={<Article/>}
                    tooltipTitle="Article"
                    tooltipOpen
                    onClick={handleFabCreate}
                />
            </SpeedDial>
        </Box>
    )
}