import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

interface NavigationBlocker {
  retry?: () => void;
}

interface Navigator {
  block: (callback: (tx: NavigationBlocker) => void) => () => void;
}

export const usePrompt = (shouldBlock = false) => {
    const [isBlocked, setIsBlocked] = useState(false);
    const unblockCallback = useRef<(() => void) | null>(null);
    const navigationContext = useRef<NavigationBlocker | null>(null);
    
    const { navigator } = useContext(NavigationContext);
    const typedNavigator = navigator as unknown as Navigator;

    const proceedNavigation = useCallback(() => {
        if (unblockCallback.current) {
            unblockCallback.current();
        }
        if (navigationContext.current?.retry) {
            navigationContext.current.retry();
        }
    }, []);

    useEffect(() => {
        if (!shouldBlock) return;

        const unblock = typedNavigator.block((tx) => {
            setIsBlocked(true);
            navigationContext.current = tx;
        });

        unblockCallback.current = unblock;
        return unblock;
    }, [typedNavigator, shouldBlock]);

    return [isBlocked, setIsBlocked, proceedNavigation] as const;
};