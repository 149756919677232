import Grid from "@mui/material/Grid";
import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import {Code, Image, Wysiwyg, YouTube} from "@mui/icons-material";
import {useDispatch} from 'react-redux'
import {addBlockToPage} from "../features/article/articleSlice";


export default function AddNewBlockButton(props) {
    const [isHovering, setIsHovering] = useState(false);
    const dispatch = useDispatch();
    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        dispatch(addBlockToPage({
            pageId: props.pageId,
            currentIndex: props.currentIndex,
            type: props.type
        }))
        props.handleClose();
    };
    const modalButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        aspectRatio: {xs:'1/.5', md:'1 / 1'},
        width:'100%'
    }

    const modalButtonInner = {
        background: (isHovering ? '#d2d2d2' : '#e3e3e3'),
        width:'100%',
        height:'100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor:'pointer',
        position: 'relative'
    }

    let button: JSX.Element | null = null;
    if(props.type === 'HtmlBlock') {
        button = (
            <Stack component="div" sx={modalButtonInner}>
                <Wysiwyg sx={{ fontSize: 66 }} />
                <Box sx={{ position:'absolute', bottom:'5px' }} component="span">Text</Box>
            </Stack>
        )
    }
    if(props.type === 'ImageBlock') {
        button = (
            <Stack component="div" sx={modalButtonInner}>
                <Image sx={{ fontSize: 66 }} />
                <Box sx={{ position:'absolute', bottom:'5px' }} component="span">Image</Box>
            </Stack>
        )
    }
    if(props.type === 'VideoBlock') {
        button = (
            <Stack component="div" sx={modalButtonInner}>
                <YouTube sx={{ fontSize: 66 }} />
                <Box sx={{ position:'absolute', bottom:'5px' }} component="span">Video</Box>
            </Stack>
        )
    }
    if(props.type === 'EmbedBlock') {
        button = (
            <Stack component="div" sx={modalButtonInner}>
                <Code sx={{ fontSize: 66 }} />
                <Box sx={{ position:'absolute', bottom:'5px' }} component="span">Embed</Box>
            </Stack>
        )
    }

    return (
        <Grid item sx={modalButtonStyle} xs={3} md={6} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleClick}>
            {button}
        </Grid>
    )
}